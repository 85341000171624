<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.getters.darkMode }">
    <promo-modal
    v-if="showPromoModal"
    :show="showPromoModal"/>
    <CWrapper class="wrapper">
    <portal-target name="login"/>
<!--      <div class="overlay">-->
<!--      </div>-->

      <Header/>
      <LineRunner v-if="$store.getters['user/getPermissions']('view_run_line') && showLineRunner" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="container-custom">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>

            <portal-target v-if="isBrave" name="brave-notify-modal"/>
          </CContainer>
        </main>
      </div>

<!--      <div class="overlay-footer">-->
<!--        <button @click="prevStep">-->
<!--          prev-->
<!--        </button>-->

<!--        <span>-->
<!--          {{ currentStep + 1 }} / {{ stepsData.length }}-->
<!--        </span>-->

<!--        <button @click="nextStep">-->
<!--          next-->
<!--        </button>-->
<!--&lt;!&ndash;        <div id="tooltip" role="tooltip">&ndash;&gt;-->
<!--&lt;!&ndash;          My tooltip&ndash;&gt;-->
<!--&lt;!&ndash;          <div data-popper-arrow></div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
    </CWrapper>
  </div>
</template>

<script>
import PromoModal from '@/components/PromoModal'
import Header from "@/components/Header";
import LineRunner from "@/components/LineRunner";
import { createPopper } from '@popperjs/core';
import tippy from 'tippy.js'
import { mapState, mapGetters } from "vuex";

export default {
  name: "Graph",
  components: {
    Header,
    LineRunner,
    PromoModal
  },
  data() {
    return {
      steps: [],
      tippyOptions: {
        showOnCreate: true,
        zIndex: 100003,
        trigger: 'manual',
        hideOnClick: false,
        allowHTML: true,
        theme: 'transparent',
      },
      stepsData: [
        {
          header: '',
          text: '',
          img: '',
          tooltips: [],
          tippyInstances: [],
          element: null,
        },
        {
          tooltips: [
            {
              header: 'Тикер',
              text: 'Тут вы можете переключить тикер пар к BTC, если в данной бирже мы тянем эту информацию',
              placement: 'top-start',
              offset: [300, 20],
              maxWidth: 800,
              theme: 'transparent top-start-arrow-position',
            },
            {
              header: 'График',
              text: 'В данном блоке вы можете посмотреть ситуацию по BTC в общем графике и по каждой бирже',
              placement: 'right',
              offset: [0, 70],
            }
          ],
          tippyInstances: [],
          element: null,
        },
        {
          tooltips: [
            {
              header: 'Markets Volume',
              text: 'В данном блоке вы можете нажать на название биржи и график слева переключиться на эту биржу',
              placement: 'left',
              offset: [0, 70],
            }
          ],
          tippyInstances: [],
          element: null,
        },
        {
          tooltips: [
            {
              header: 'Overall Volume',
              text: 'В данном блоке вы можете нажать на название биржи и график слева переключиться на эту биржу',
              placement: 'left',
              offset: [0, 70],
            }
          ],
          tippyInstances: [],
          element: null,
        },
        {
          tooltips: [
            {
              header: 'Навигация',
              text: 'Если активна эта функция, то в стакане Overall Volume при клике по точке на графике, отобразится выбранное значение',
              placement: 'left',
              offset: [0, 70],
            }
          ],
          tippyInstances: [],
          element: null,
        },
        {
          tooltips: [
            {
              header: 'Фильтр по дате',
              text: 'Вы можете выбрать только нужный временной диапазон',
              placement: 'left',
              offset: [0, 70],
            }
          ],
          tippyInstances: [],
          element: null,
        },
      ],
      currentStep: 0
    }
  },
  computed: {
    ...mapGetters({
      showPromoModal: 'promo/showPromoModal',
    }),
    ...mapState({
      isBrave: state => state.browserNotify.isBrave,
      showLineRunner: state => state.lineRunner.showLineRunner,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.getSteps()

      const observer = new MutationObserver(() => {
        this.getSteps()
      })

      observer.observe(document.querySelector('.c-body'), {
        attributes: true,
        childList: true,
        subtree: true
      })
    })
  },
  methods: {
    getTippyContent({ header, text }) {
      return `<div class="tippy-transparent-header">${header}</div><div>${text}</div>`
    },
    getSteps() {
      document.querySelectorAll('[data-onboarding-step]').forEach(el => {
        const stepNum = Number(el.getAttribute('data-onboarding-step'))

        if (!isNaN(stepNum) && this.stepsData[stepNum - 1]) {
          this.$set(this.stepsData[stepNum - 1], 'element', el)
        }
      })
    },
    setTippyForStep(step, show = true) {
      if (step?.element) {
        if (step.tippyInstances.length) {
          step.tippyInstances.forEach(instance => {
            if (show) {
              instance.show()
            } else {
              instance.hide()
            }
          })
        } else if (show) {
          step.tooltips.forEach(el => {
            step.tippyInstances.push(
              tippy(step.element, {
                ...this.tippyOptions,
                ...el,
                content: this.getTippyContent(el)
              })
            )
          })
        }
      }
    },
    nextStep() {
      const stepData = this.stepsData[this.currentStep + 1]

      if (!stepData) {
        if (this.currentStep > 0) {
          this.currentStep--
          this.nextStep()
        }

        return
      }

      if (stepData.element) {
        this.stepsData.forEach(step => {
          if (step.element) {
            this.setTippyForStep(step, false)
            step.element.style.removeProperty('z-index')

            if (step.element.getAttribute('data-position')) {
              step.element.removeAttribute('data-position')
              step.element.style.removeProperty('position')
            }
          }
        })

        this.setTippyForStep(stepData)

        const isStatic = window.getComputedStyle(stepData.element).position === 'static'

        stepData.element.style.setProperty('z-index', 100001)

        if (isStatic) {
          stepData.element.style.setProperty('position', 'relative')
          stepData.element.setAttribute('data-position', true)
        }

        stepData.element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
        this.currentStep++
      } else if (this.currentStep < this.stepsData.length - 1) {
        this.currentStep++
        this.nextStep()
      }
    },
    prevStep() {
      this.currentStep--

      const step = this.steps[this.currentStep]

      if (step) {
        this.steps.forEach(step => {
          step.el.style.removeProperty('z-index')
        })
        step.el.style.setProperty('z-index', 100001)
        step.el.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";
.wrapper {
  //@include media-breakpoint-up(lg) {
    height: 100vh;
    height: 100dvh;
  //}
}
.c-body,
.c-main,
.container-custom {
  //@include media-breakpoint-up(lg) {
    overflow-y: hidden;
    height: 100%;
  //}
}

.container-fluid {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding-left: .94rem;
    padding-right: .94rem;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.c-main {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
}


.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: #000;
  opacity: .8;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.overlay-footer {
  position: fixed;
  width: 100%;
  z-index: 100002;
  bottom: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}
[data-popper-arrow] {
  & {
    position: absolute;
    width: 30px;
    height: 2px;
    background: white;
    visibility: visible;
  }
}

#tooltip[data-popper-placement^='top'] > [data-popper-arrow] {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > [data-popper-arrow] {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > [data-popper-arrow] {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > [data-popper-arrow] {
  left: -4px;
}
</style>
